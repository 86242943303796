import type { NextPage } from 'next';
import Link from 'next/link';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { useIsMobile } from '@hooks/responsive/useIsMobile';

// 다국어 지원
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

// color
import { primary, blue, gray, red, white, black } from '@styles/Colors';

// components
import DefaultLayout from '@components/_templates/DefaultLayout';
import Typography from '@components/_atoms/Typography';
import Button from '@components/_atoms/Button';
import RedErrorIcon from '@public/assets/3dIcons/3dRedWarning.svg';

//constants
import { TypoVariant } from '@constants/atoms';
import { ButtonColor } from '@constants/atoms';
import { MOBILE_MODE } from '@constants/size';
import { HOME } from '@constants/routes/routes';
import Head from 'next/head';

const ErrorWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 360px 0;
  margin: 0 auto;
  text-align: center;

  @media ${MOBILE_MODE} {
    max-width: 320px;
    padding: 224px 0;
  }
`;

const TitleWrapper = styled.div`
  margin-top: 16px;
`;

const ContentWrapper = styled.div`
  margin-top: 8px;
`;

const ButtonWrapper = styled.div`
  margin: 324px auto 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;

  @media ${MOBILE_MODE} {
    align-items: stretch;
    margin-top: 132px;
  }
`;

const Error: NextPage = () => {
  const isMobile = useIsMobile();
  const router = useRouter();

  const { t } = useTranslation('error');

  return (
    <>
      <Head>
        <title>{t('404.meta-title')}</title>
        <meta name="title" content={t('404.meta-title')} />
      </Head>
      <DefaultLayout>
        <ErrorWrapper>
          <RedErrorIcon />
          <TitleWrapper>
            <Typography variant={isMobile ? TypoVariant.SH2 : TypoVariant.H6} color={primary.gray}>
              {t('404.message1')}
            </Typography>
          </TitleWrapper>
          <ContentWrapper>
            <Typography variant={isMobile ? TypoVariant.B3 : TypoVariant.B2} color={gray.gray7}>
              {t('404.message2')}
            </Typography>
          </ContentWrapper>
          <ButtonWrapper>
            <Link href={HOME}>
              <a>
                <Button width="100%" text={t('404.home-button')} color={ButtonColor.OutlinedGray} />
              </a>
            </Link>
            <Button text={t('404.back-button')} color={ButtonColor.TransparentGray} onClick={() => router.back()} />
          </ButtonWrapper>
        </ErrorWrapper>
      </DefaultLayout>
    </>
  );
};

export const getStaticProps = async ({ locale }: { locale: any }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['error', 'common', 'component'])),
  },
});

export default Error;
